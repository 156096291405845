import React from 'react'
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import './featuresList.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const FeaturesList = (props) => {

  if(!props) return null;

  return (
    <section className="kb__features-list">
      <div className="kb__container">
      
        <div className="kb__grid-center">
          <div className="kb__col-12_sm-12 kb--center">
            <h3>{RichText.asText(props.list.primary.features_list_title)}</h3>
          </div>

          {props.list.fields && 
            <div className="kb__col-12_sm-12">
              <div className="kb__grid">

              {props.list.fields.map((item,i) => {
                return(
                  <div key={i} className="kb__col-6_sm-12 kb__features-list-item">
                    <div className="kb__grid">
                      <div className="kb__col-2_sm-2 kb__features-list-icon">
                        <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.22841 62.1022L48.5146 42.2257C50.7721 41.2125 50.9014 38.0559 48.7343 36.8614L4.44814 12.4517C2.44873 11.3497 0 12.7961 0 15.0791L0 59.3652C0 61.5409 2.24347 62.9931 4.22841 62.1022Z" fill="#A58BEE"/>
                          <path d="M11.2284 52.1022L55.5146 32.2257C57.7721 31.2125 57.9014 28.0559 55.7343 26.8614L11.4481 2.45173C9.44873 1.34969 7 2.79605 7 5.07907L7 49.3652C7 51.5409 9.24347 52.9931 11.2284 52.1022Z" fill="#7047E5"/>
                        </svg>
                      </div>
                      <div className="kb__col-10_sm-10">
                        {RichText.render(item.feature_indv_title)}
                        {RichText.render(item.feature_indv_description)}
                      </div>
                    </div>
                  </div>
                )
              })}  
              </div>
            </div>
          }

          </div>

      </div>
    </section>
  )
}

export default FeaturesList

import React from 'react'
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import StepShapeCircle from '../images/step-shape-circle.svg'
import StepShapeSemiCircle from '../images/step-shape-semi-circle.svg'
import StepShapeTriangle from '../images/step-shape-triangle.svg'
import StepShapeRectangle from '../images/step-shape-rectangle.svg'

import './stepGrid.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const StepsGrid = (props) => {

    return (
      <section className="kb__step-grid">
        <div className="kb__container--full">
          <div className="kb__grid-center">
            <div className="kb__col-10_sm-12">
              <div className="kb__grid">


              {props.steps.fields.map((step,i) => {
                  return(
                    <div key={i} className="kb__col-7_md-10_sm-12 kb__step" data-step={i} data-push-left={'off-' + ((i + 1))}>
                      <div className="kb__grid-middle-noGutter">
                        <div className="kb__col-5_sm-4 kb__step-shape-wrapper">
                          
                          <div className="kb__step-shape--circle">
                            <span className="kb__step-count">{i + 1}</span>
                          </div>

                          <div className="kb__step-background">
                              <div className="kb__step-background-shape">
                                <img className="kb__step-background-shape--circle" src={StepShapeCircle} alt="" />
                                <img className="kb__step-background-shape--semi-circle" src={StepShapeSemiCircle} alt="" />
                                <img className="kb__step-background-shape--triangle" src={StepShapeTriangle} alt="" />
                                <img className="kb__step-background-shape--rectangle" src={StepShapeRectangle} alt="" />
                              </div>
                          </div>
                        </div>
                        
                        <div className="kb__col-6_sm-7" data-push-left="off-1">
                          
                          {RichText.render(step.step_title)}
                          
                          {RichText.render(step.step_text)}
                          
                          {step.step_cta_text &&
                            <a href={RichLink(step.step_cta_link)} title={step.step_cta_text}>{step.step_cta_text}</a>
                          } 
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    )

}

export default StepsGrid
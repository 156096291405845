import React from 'react'

import './campaignCard.scss'

const CampaignCard = ({

  campaign: {
    image_url: imageUrl,
    commission_percentage: commissionPercentage,
    cookie_duration: cookieDuration,
    name,
    pledged,
    goal,
    days_left_text: daysLeftText,
    campaign_type: campaignType,
    kickbooster_redirect_url: kickboosterRedirectUrl,
    booster_page_url: boosterPageUrl,
    prelaunch_page_url: preLaunchPageUrl,
    platform,
    is_boosted: isBoosted,
  },
}) => {

  var cardText = ""
  var ctaLink = ""
  var platformText = ""
  var actionsText = ""

  if(campaignType === "prelaunch"){
    cardText = "Coming soon"
    ctaLink = preLaunchPageUrl
    platformText = 'View campaign on ' + platform
    actionsText = <p>Coming soon</p>
  } else if(campaignType === "crowdfunding"){
    cardText = daysLeftText
    ctaLink = boosterPageUrl
    platformText = 'View campaign on ' + platform
    actionsText = <p>{pledged} of {goal} Raised</p>
  } else if(campaignType === "indemand"){
    cardText = 'In demand'
    ctaLink = boosterPageUrl
    platformText = 'View campaign on ' + platform
    actionsText = <p>{pledged} of {goal} Raised</p>
  } else if(campaignType === "shopify"){
    ctaLink = boosterPageUrl
    platformText = "Shop now"
    actionsText = <p>Cookie duration: {cookieDuration}</p>
  } else {
    ctaLink = boosterPageUrl
    platformText = 'Learn more'
    actionsText = <p>{pledged} of {goal} Raised</p>
  }
  
  return(
    <div className="kb__col-4_lg-6_md-6_sm-12">
      <div className="kb__campaign-card">

        <a href={ctaLink} title="Sign up to support" className="kb__campaign-card-banner">
          <img src={imageUrl} alt={name} />
          <div className="kb__campaign-card-details">
              <div className="kb__campaign-details-commission">
                {campaignType !== 'prelaunch' && (
                  <>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8119 0.522095C13.7794 0.37085 13.6298 0.221313 13.4781 0.188843C12.5977 0 11.9084 0 11.2221 0C8.39932 0 6.70658 1.50946 5.44284 3.5H2.59322C2.14621 3.50043 1.62097 3.82471 1.42054 4.22418L0.0687865 6.92609C0.0270127 7.01823 0.00364602 7.11765 0 7.21875C6.2854e-05 7.39281 0.0692453 7.55971 0.192337 7.68278C0.315428 7.80584 0.482352 7.87498 0.656409 7.875H3.49497L2.88043 8.48938C2.56967 8.80004 2.52507 9.37143 2.88043 9.72668L4.27235 11.1187C4.5774 11.4246 5.15162 11.4784 5.5104 11.1187L6.12494 10.5043V13.3438C6.125 13.5178 6.19418 13.6847 6.31727 13.8078C6.44036 13.9308 6.60729 14 6.78134 14C6.88247 13.9963 6.9819 13.9729 7.07411 13.9312L9.77372 12.5807C10.1737 12.3812 10.4985 11.8561 10.4985 11.4088V8.55304C12.4836 7.28668 13.9995 5.58881 13.9995 2.78094C14.0021 2.0918 14.0021 1.40265 13.8119 0.522095ZM10.5011 4.59375C10.2848 4.5937 10.0734 4.52951 9.89352 4.40929C9.71369 4.28908 9.57354 4.11824 9.4908 3.91838C9.40806 3.71852 9.38643 3.49861 9.42867 3.28646C9.4709 3.07431 9.57509 2.87945 9.72806 2.72651C9.88104 2.57357 10.0759 2.46943 10.2881 2.42725C10.5002 2.38507 10.7201 2.40674 10.92 2.48953C11.1198 2.57232 11.2906 2.71251 11.4108 2.89237C11.531 3.07223 11.5951 3.28369 11.5951 3.5C11.5949 3.79007 11.4796 4.06822 11.2745 4.27331C11.0693 4.47839 10.7912 4.59365 10.5011 4.59375Z" fill="#FEFEFE"/>
                    </svg>
                    <span>{commissionPercentage}%</span>
                  </>
                )}
              </div>

            <div className="kb__campaign-details-time">
            {platform === "eCommerce" 
              ? null
              : (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0.21875C3.25391 0.21875 0.21875 3.25391 0.21875 7C0.21875 10.7461 3.25391 13.7812 7 13.7812C10.7461 13.7812 13.7812 10.7461 13.7812 7C13.7812 3.25391 10.7461 0.21875 7 0.21875ZM9.52902 8.77734L8.98215 9.46094C8.94626 9.50581 8.90188 9.54317 8.85156 9.57089C8.80123 9.59861 8.74593 9.61614 8.68883 9.62249C8.63172 9.62884 8.57393 9.62387 8.51874 9.60788C8.46355 9.59189 8.41205 9.56519 8.36719 9.5293L6.53516 8.16977C6.40715 8.06728 6.30382 7.93732 6.23283 7.7895C6.16183 7.64169 6.12498 7.4798 6.125 7.31582V3.0625C6.125 2.94647 6.17109 2.83519 6.25314 2.75314C6.33519 2.67109 6.44647 2.625 6.5625 2.625H7.4375C7.55353 2.625 7.66481 2.67109 7.74686 2.75314C7.82891 2.83519 7.875 2.94647 7.875 3.0625V7L9.46094 8.16211C9.50583 8.19802 9.5432 8.24243 9.57092 8.29279C9.59863 8.34315 9.61616 8.39848 9.62248 8.45562C9.6288 8.51275 9.6238 8.57058 9.60777 8.62578C9.59173 8.68098 9.56497 8.73249 9.52902 8.77734Z" fill="#FEFEFE"/></svg>)
            }
              <span>{cardText}</span>
            </div>
          </div>
        </a>

        <div className="kb__campaign-card-info">
          <h4>{name}</h4>

          <div className="kb__campaign-card-footer">
            {actionsText}

            <div className="kb__campaign-actions">

              {isBoosted === false
                ? (
                    <>
                      <a className="kb__button--secondary" target="_blank" rel="noreferrer" href={kickboosterRedirectUrl} title="View campaign">{platformText}</a>
                      <a className="kb__button--primary" href={ctaLink} title="Boost campaign">Boost this campaign</a>
                    </>
                  )
                : (
                  <>
                    <p className="kb__already-boosted">Already boosting</p>
                  </>
                )
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CampaignCard
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import './careerList.scss';

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const CareerList = () => {
  const data = useStaticQuery(graphql`
    {
      prismic {
        allCareers {
          edges {
            node {
              _meta {
                uid
                type
                tags
              }
              _linkType
              title
            }
          }
        }
      }
    }
  `)

  const doc = data.prismic.allCareers.edges;

  const productJobs = [];
  const marketingJobs = [];
  const clientSuccessJobs = [];
  const partnerJobs = [];

  doc.map((career,i) => {
    if(career.node._meta.tags.includes('Product Team')){
      return productJobs.push(career);
    }else if(career.node._meta.tags.includes('Marketing and Sales Team')){
      return marketingJobs.push(career);
    }else if(career.node._meta.tags.includes('Client Success Team')){
      return clientSuccessJobs.push(career);
    }else if(career.node._meta.tags.includes('Partnerships Team')){
      return partnerJobs.push(career);
    }else{
      return null;
    }
  })

  return (
    <section className="kb__careers-list">
      <div className="kb__container--full">
        <div className="kb__grid-center">
          <div className="kb__col-6_md_8_sm-12">

            <div className="kb__grid">
              
              <div className="kb__col-6">
                <h6 className="big-body">Product</h6>

                <div className="kb__career-links big-body">
                  {productJobs.length > 0
                    ? (
                      productJobs.map((career,i) => {
                        return(
                          <div key={i}>
                            <div className="kb__career-link">
                              <a href={RichLink(career.node)}>{RichText.asText(career.node.title)}</a>
                            </div>
                          </div>
                        )
                      })
                    )
                    : (<p className="kb--italic">No openings</p>)
                  }
                </div>

                

              </div>

              <div className="kb__col-6">
                <h6 className="big-body">Marketing &amp; Sales</h6>

                <div className="kb__career-links big-body">
                  {marketingJobs.length > 0
                    ? (
                      marketingJobs.map((career,i) => {
                        return(
                          <div key={i}>
                            <div className="kb__career-link">
                              <a href={RichLink(career.node)}>{RichText.asText(career.node.title)}</a>
                            </div>
                          </div>
                        )
                      })
                    )
                    : (<p className="kb--italic">No openings</p>)
                  }
                </div>
              </div>

              <div className="kb__col-6">
                <h6 className="big-body">Client Success</h6>

                <div className="kb__career-links big-body">
                  {clientSuccessJobs.length > 0
                    ? (
                      clientSuccessJobs.map((career,i) => {
                        return(
                          <div key={i}>
                            <div className="kb__career-link">
                              <a href={RichLink(career.node)}>{RichText.asText(career.node.title)}</a>
                            </div>
                          </div>
                        )
                      })
                    )
                    : (<p className="kb--italic">No openings</p>)
                  }
                </div>
              </div>

              <div className="kb__col-6">
                <h6 className="big-body">Partnerships</h6>

                <div className="kb__career-links big-body">
                  {partnerJobs.length > 0
                    ? (
                      partnerJobs.map((career,i) => {
                        return(
                          <div key={i}>
                            <div className="kb__career-link">
                              <a href={RichLink(career.node)}>{RichText.asText(career.node.title)}</a>
                            </div>
                          </div>
                        )
                      })
                    )
                    : (<p className="kb--italic">No openings</p>)
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerList

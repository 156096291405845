import React from 'react';

import SwiperCore, { Pagination, A11y, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import IconChevronLeft from '../images/icon-chevron-left.svg'
import IconChevronRight from '../images/icon-chevron-right.svg'

import './imageSlider.scss'

SwiperCore.use([Pagination, A11y, Navigation, Autoplay]);


const ImageSlider = (props) => {

  if(!props) return null;

  if (!props.images) return null;

  const images = props.images;

  return(

    <section className="kb__image-slider">
      <div className="kb__container">
        <div className="kb__grid-center">
          <div className="kb__col-10_sm-12">

            {images.length > 0 && (
              <div className="kb__slider">
              
                <Swiper
                  autoHeight={true}
                  autoplay={{delay: 4000, disableOnInteraction: true}}
                  pagination={{el: '.kb__campaigns-pagination', clickable: true}}
                  navigation={{nextEl: '.kb__campaigns-next', prevEl: '.kb__campaigns-prev'}}
                  grabCursor={true}
                  loop={true}
                >
                  {images.map((image,i) => {
                    return(
                      <SwiperSlide key={i}>
                        <img src={image.slide_image.url} alt={image.slide_image.alt} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              

                <div className="kb__campaigns-nav">
                  <button className="kb__campaigns-nav-button kb__campaigns-prev">
                    <img src={IconChevronLeft} alt="" />
                  </button>
                  
                  <div className="kb__campaigns-pagination"></div>

                  <button className="kb__campaigns-nav-button kb__campaigns-next">
                    <img src={IconChevronRight} alt="" />
                  </button>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>

    </section>
  )

}

export default ImageSlider
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {RichText, Link} from "prismic-reactjs"
import linkResolver from '../utils/linkResolver'

import './blogCard.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const BlogListNews = () => {


  const data = useStaticQuery(graphql`
    query blogListNewsQuery{
      prismic {
        allPosts(sortBy: meta_firstPublicationDate_DESC, tags: "Kickbooster News") {
          edges {
            node {
              title
              body {
                ... on PRISMIC_PostBodyBig_image {
                  type
                  primary {
                    big_image
                  }
                }
              }
              _meta {
                uid
                type
                tags
              }
              _linkType
            }
          }
        }
      }
    }
  `)

  const articles = data.prismic.allPosts.edges;


  if (!articles) return null;


  return (
    <section className="kb__blog-list">
      <div className="kb__container--full">
        <div className="kb__grid-center">
          <div className="kb__col-8_md-10_sm-12">
            <div className="kb__grid">
              {articles.map((article,i) =>{
                return(
                  <div key={i} className={i === 0 ? 'kb__col-12' : 'kb__col-6_sm-12'}>
                    
                      <div className="kb__blog-card">
                        <a href={RichLink(article.node)} title={RichText.asText(article.node.title)}>
                          <img src={i === 0 ? article.node.body[0].primary.big_image.url : article.node.body[0].primary.big_image.thumbnail.url} alt="kb" />
                        </a>

                        <div className="kb__blog-card-info">
                          <a href={RichLink(article.node)} title={RichText.asText(article.node.title)}>
                            <h4>{RichText.asText(article.node.title)}</h4>
                          </a>


                          <a href="/blog/kickbooster-news" title="Catch up with the latest in Kickbooster news">
                            {article.node._meta.tags}
                          </a>

                        </div>
                        
                      </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogListNews
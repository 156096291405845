import React from "react"
import {RichText, Link} from "prismic-reactjs"
import linkResolver from '../utils/linkResolver'

import './successCard.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const SuccessCard = (props) => {

  if(!props.success)return null;

  return (
    <div className="kb__col-4_md-6_sm-12">
      <div className="kb__success-card">
        <a href={RichLink(props.success.node)} title="Learn more about their success with Kickbooster">
          <img src={props.success.node.success_story_image.thumb.url} alt="Lorem" />
        </a>
        <div className="kb__success-card-info">
          <div className="kb__success-card-blurb">
            <p>{RichText.asText(props.success.node.success_story_sub_title)}</p>
          </div>

          <a href={RichLink(props.success.node)} title="Learn more about their success with Kickbooster">
            <h3>{RichText.asText(props.success.node.success_story_title)}</h3>
          </a>
          
        </div>
      </div>
    </div>
  )
}

export default SuccessCard
import React from 'react'
import {RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import FAQs from './faqs'
import InlineSVG from './inlineSVG'

import './featuredPricing.scss'
import './pricingPanel.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const FeaturedPricing = (props) => {
  
  if(!props.panel) return null;

  return (
    <section className="kb__featured-price">
      <div className="kb__container--narrow">
        <div className="kb__pricing-panel kb__featured-pricing kb__panel kb__panel--active">

          <div className="kb__pricing-panel-blurb">
            <h3>Pricing</h3>
          </div>

          <div className="kb__price">
            <div className="kb__grid-center-middle">
              <div className="kb__price--1">
                <h2 className="super-headline">{RichText.asText(props.panel.price_1_title)}</h2>
                <p className="big-body">{RichText.asText(props.panel.price_1_sub_title)}</p>
              </div>

              {props.panel.price_2_title && 
              <div className="kb__price--2">
                <h2 className="super-headline">{RichText.asText(props.panel.price_2_title)}</h2>
                <p className="big-body">{RichText.asText(props.panel.price_2_sub_title)}</p>
              </div>
              }
            </div>
          </div>

          {props.panel.body &&
            props.panel.body.map((slice,i) => {
              if(slice.type === "checkmark_list"){
                return(
                  <div key={i} className="kb__list">
                    <div className="kb__grid">
                      {slice.fields.map((item,i) => {
                        return(
                          <div key={i} className="kb__col-6_sm-12">
                            <div className="kb__list-wrap">
                              <InlineSVG url={slice.primary.list_image.url} classes="kb__list-image" />
                              <p className="big-body">{RichText.asText(item.list_text)}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              } else if(slice.type === "fine_print"){
                return(
                  <div className="kb__fine-print kb__body-sm" key={i}>
                    {slice.primary.text &&
                      RichText.render(slice.primary.text)
                    }
                  </div>
                )
              } else {
                return null;
              }
            })
          }

          {props.panel.price_cta && 
            <div className="kb__actions">
              <a className="kb__button--primary kb__button--large" href={RichLink(props.panel.price_cta)}>{RichText.asText(props.panel.price_cta_text)}</a>
            </div>
          }


          {props.panel.body &&
            props.panel.body.map((slice,i) => {
              if(slice.type === "faqs"){
                return(
                  
                  <FAQs key={i} faqs={slice} />
                  
                )
              } else {
                return null;
              }
            })
          }
        </div>
      </div>
    </section>
  )
}

export default FeaturedPricing

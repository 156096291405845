import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import {RichText} from 'prismic-reactjs'

import Banner from '../components/banner'
import Features from '../components/features'
import RichTextBlock from '../components/richTextBlock'
import FAQs from '../components/faqs'
import Marketplace from '../components/marketplace'
import SupportCards from '../components/supportCards'
import IconGrid from '../components/iconGrid'
import LinkBlock from '../components/linkBlock'
import PartnerForm from '../components/partnerForm'
import StepGrid from '../components/stepsGrid'
import MarketplaceHeader from '../components/marketplaceHeader'
import MarketplaceBody from '../components/marketplaceBody'
import FeaturedSuccessStories from '../components/featuredSuccessStories'
import FeaturesList from '../components/featuresList'
import PricingTabs from '../components/pricingTabs'
import FeaturedPricing from '../components/featuredPricing'
import FeaturedReviews from '../components/featuredReviews'
import CaseStudies from '../components/caseStudies'
import BlogList from '../components/blogList'
import SuccessList from '../components/successList'
import EcommForm from '../components/ecommForm'
import CareerList from '../components/careerList'
import InlineSVG from '../components/inlineSVG'
import ImageSlider from '../components/imageSlider'
import PManagerDemoForm from '../components/pManagerGatedDemo'

export const query = graphql`
query pageQuery($uid: String) {
  prismic {
    allPages(uid: $uid) {
      edges {
        node {
          body {
            ... on PRISMIC_PageBodyBanner {
              type
              label
              primary {
                center_layout
                cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                cta_text
                darker_shapes
                heading_highlight
                heading_part_1
                heading_part_2
                sub_heading
                cta_internal_id
              }
            }
            ... on PRISMIC_PageBodyFeatures_section {
              type
              label
              primary {
                sub_head
                main_heading
                features_section_heading
                features_section_sub_heading
              }
              fields {
                cta_1_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
                shape_background
                scroll_to_card_title
                scroll_card_icon
                feature_title
                feature_image
                feature_blurb
                feature
                cta_2_text
                cta_2_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                cta_1_text
              }
            }
            ... on PRISMIC_PageBodyRichtext {
              type
              label
              primary {
                center_layout
                rich_text_cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      tags
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC_Career {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
                rich_text_cta_link_2 {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      tags
                      type
                      uid
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC_Career {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                }
                richtext
                big_body_
                rich_text_title
                rich_text_cta_text
                rich_text_cta_text_2
                cta_internal_id
              }
            }
            ... on PRISMIC_PageBodyFaqs {
              type
              label
              fields {
                faq_answer
                faq_question
              }
              primary {
                faq_section_title
              }
            }
            ... on PRISMIC_PageBodyMarketplace {
              type
              label
              primary {
                marketplace_title
              }
              fields {
                campaign {
                  ... on PRISMIC_Campaign {
                    campaign_image
                    campaign_blurb
                    campaign_booster_link {
                      ... on PRISMIC__ExternalLink {
                        _linkType
                        url
                      }
                      _linkType
                    }
                    campaign_title
                    campaign_platform_link {
                      ... on PRISMIC__ExternalLink {
                        _linkType
                        url
                      }
                      _linkType
                    }
                    campaign_name
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
              }
            }
            ... on PRISMIC_PageBodyIcon_grid {
              type
              label
              fields {
                icon_card_description
                icon_card_icon
                icon_card_title
                icon_card_image
                icon_card_link_text
                icon_card_link {
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  _linkType
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC_Career {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      type
                      uid
                      tags
                    }
                  }
                }
              }
              primary {
                icon_grid_title
                cols_desktop
                cols_mobile
                grid_with_gutter
                cols_tablet
                inset_grid
                extra_inset_grid
                has_border
                has_dropshadow
              }
            }
            ... on PRISMIC_PageBodySteps {
              type
              label
              fields {
                step_cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      type
                      uid
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC_Career {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
                step_title
                step_text
                step_shape
                step_cta_text
              }
              primary {
                steps_section_title
              }
            }
            ... on PRISMIC_PageBodySupport_cards {
              type
              label
            }
            ... on PRISMIC_PageBodyLink_block {
              type
              label
              primary {
                link_block_link {
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      type
                      uid
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC_Career {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
                link_block_icon
                link_block_text
              }
            }
            ... on PRISMIC_PageBodyPartners_form {
              label
              primary {
                partners_form_title
              }
              type
            }
            ... on PRISMIC_PageBodyP_manager_form {
              type
              label
              primary {
                pledge_manager_form_title
              }
            }
            ... on PRISMIC_PageBodyTitle {
              type
              label
              primary {
                single_line_title
                center_title
              }
            }
            ... on PRISMIC_PageBodySuccess_stories {
              type
              label
              primary {
                success_stories_title
              }
            }
            ... on PRISMIC_PageBodyFeatures_list {
              type
              label
              fields {
                feature_indv_description
                feature_indv_title
              }
              primary {
                features_list_title
              }
            }
            ... on PRISMIC_PageBodyMarketplace_header {
              type
              label
              primary {
                marketplace_header_title
              }
            }
            ... on PRISMIC_PageBodyMarketplace_body {
              type
              label
              primary {
                marketplace_body_title
              }
            }
            ... on PRISMIC_PageBodyPricing_tabs {
              type
              label
              fields {
                pricing_tab {
                  _linkType
                  ... on PRISMIC_Pricing {
                    pricing_name
                    pricing_title
                    _linkType
                    body {
                      ... on PRISMIC_PricingBodyCheckmark_list {
                        type
                        label
                        fields {
                          list_text
                        }
                        primary {
                          list_image
                        }
                      }
                      ... on PRISMIC_PricingBodyFaqs {
                        type
                        label
                        fields {
                          faq_answer
                          faq_question
                        }
                        primary {
                          faqs_section_title
                        }
                      }
                      ... on PRISMIC_PricingBodyFine_print {
                        type
                        label
                        primary {
                          text
                        }
                      }
                    }
                    price_1_sub_title
                    price_1_title
                    price_2_sub_title
                    price_2_title
                    cta_internal_id
                    price_cta {
                      _linkType
                      ... on PRISMIC_Page {
                        title
                        _linkType
                        _meta {
                          uid
                          type
                          tags
                        }
                      }
                      ... on PRISMIC_Post {
                        title
                        _linkType
                        _meta {
                          type
                          uid
                          tags
                        }
                      }
                      ... on PRISMIC__ExternalLink {
                        target
                        _linkType
                        url
                      }
                    }
                    price_cta_text
                    pricing_sub_title
                  }
                }
              }
              primary {
                pricing_tabs_title
              }
            }
            ... on PRISMIC_PageBodyEcommerce_cases {
              type
              label
              primary {
                ecommerce_cases_title
              }
              fields {
                case_blurb
                case_cta_text
                case_company
                case_company_image
                case_cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    target
                    url
                  }
                }
                case_stat_1
                case_stat_2
                case_testimonial
                case_testimonial_author
              }
            }
            ... on PRISMIC_PageBodyFeatured_reviews {
              type
              label
              fields {
                review_customer
                review_link {
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    target
                    _linkType
                    url
                  }
                  _linkType
                }
                review_quote
              }
            }
            ... on PRISMIC_PageBodySuccess_stories_list {
              type
              label
            }
            ... on PRISMIC_PageBodyBlog_list_all {
              type
              label
              primary {
                blog_category
              }
            }
            ... on PRISMIC_PageBodyFeatured_pricing {
              type
              label
              fields {
                pricing {
                  ... on PRISMIC_Pricing {
                    pricing_name
                    pricing_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                    body {
                      ... on PRISMIC_PricingBodyCheckmark_list {
                        type
                        label
                        fields {
                          list_text
                        }
                        primary {
                          list_image
                        }
                      }
                      ... on PRISMIC_PricingBodyFine_print {
                        type
                        label
                        primary {
                          text
                        }
                      }
                    }
                    price_2_sub_title
                    price_1_sub_title
                    price_1_title
                    price_2_title
                    price_cta {
                      _linkType
                      ... on PRISMIC__ExternalLink {
                        target
                        _linkType
                        url
                      }
                      ... on PRISMIC_Page {
                        title
                        meta_title
                        _linkType
                        _meta {
                          uid
                        }
                      }
                    }
                    pricing_sub_title
                    price_cta_text
                  }
                }
              }
              primary {
                pricing_title
              }
            }
            ... on PRISMIC_PageBodyEcomm_form {
              type
              label
              primary {
                ecomm_form_sub_title
                ecomm_form_title
              }
            }
            ... on PRISMIC_PageBodyCareers_list {
              type
              label
            }
            ... on PRISMIC_PageBodyList {
              type
              label
              fields {
                list_text
              }
              primary {
                list_image
                list_title
              }
            }
            ... on PRISMIC_PageBodyImage_slider {
              type
              label
              fields {
                slide_image
              }
            }
            ... on PRISMIC_PageBodyPManager_demo {
              type
              label
              primary {
                ac_form_id
                pmanager_blurb
                pmanager_cta_text
              }
            }
          }
          title
          meta_title
          meta_description
          meta_image
          _meta {
            uid
            type
            tags
          }
        }
      }
    }
  }
}
`


const Page = props => {

  if(!props) return null;
  
  const doc = props.data.prismic.allPages.edges[0];

  if(!doc) return null;
  if(!doc.node) return null;

  const page = doc.node;

  return (
    <Layout>
    
    <SEO 
      title={RichText.asText(page.meta_title)}
      meta={[
        {
          property: `og:image`,
          content: page.meta_image.facebook.url
        },{
          property: `twitter:image`,
          content: page.meta_image.twitter.url
        }
      ]}
    />

      {page.body &&

        <div>
          {
            page.body.map((slice,i) => {

              if(slice){
                
                if (slice.type === "banner") { 
                  return ( 
                    <Banner key={i} banner={slice.primary} uid={page._meta.uid}/>
                  )
                } else if (slice.type === "features_section") {
                  return(
                    <Features key={i} features={slice} uid={page._meta.uid} />
                  )
                } else if (slice.type === "richtext") {
                  return(
                    <RichTextBlock key={i} count={i} richTextBlock={slice} />
                  )
                } else if (slice.type === "faqs") {
                  return(
                    <FAQs key={i} faqs={slice}/>
                  )
                } else if (slice.type === "marketplace") {
                  return(
                    <Marketplace key={i} marketplace={slice}>Marketplace</Marketplace>
                  )
                } else if (slice.type === "steps") {
                  return(
                    <StepGrid key={i} steps={slice} />
                  )
                } else if (slice.type === "support_cards") {
                  return(
                    <SupportCards key={i} />
                  )
                } else if (slice.type === "icon_grid") {
                  return(
                    <IconGrid key={i} grid={slice}/>
                  )
                } else if (slice.type === "link_block") {
                  return(
                    <LinkBlock key={i} linkblock={slice} />
                  )
                } else if (slice.type === "partners_form") {
                  return(
                    <PartnerForm key={i} block={slice} />
                  )
                } else if (slice.type === "marketplace_header") {
                  return(
                    <MarketplaceHeader key={i} block={slice} />
                  )
                } else if (slice.type === "marketplace_body") {
                  return(
                    <MarketplaceBody key={i} block={slice} />
                  )
                } else if (slice.type === "success_stories") {
                  return(
                    <FeaturedSuccessStories key={i} block={slice} />
                  )
                } else if (slice.type === "features_list") {
                  return(
                    <FeaturesList key={i} list={slice} />
                  )
                } else if (slice.type === "pricing_tabs") {
                  return(
                    <PricingTabs key={i} tabs={slice} />
                  )
                } else if (slice.type === "featured_pricing") {
                  return(
                    <FeaturedPricing key={i} panel={slice.fields[0].pricing} />
                  )
                } else if (slice.type === "featured_reviews") {
                  return(
                    <FeaturedReviews key={i} reviews={slice} />
                  )
                } else if (slice.type === "ecomm_form") {
                  return(
                    <EcommForm key={i} block={slice} />
                  )
                } else if (slice.type === "ecommerce_cases") {
                  return(
                    <CaseStudies key={i} cases={slice} />
                  )
                } else if (slice.type === "blog_list_all") {
                  return(
                    <BlogList key={i} category={slice.primary.blog_category}/>
                  )
                } else if (slice.type === "success_stories_list") {
                  return(
                    <SuccessList key={i} list={slice}/>
                  )
                } else if (slice.type === "image_slider") {
                  return(
                    <ImageSlider key={i} images={slice.fields}/>
                  )
                } else if (slice.type === "careers_list"){
                  return(
                    <CareerList key={i} />
                  )
                } else if (slice.type === "p-manager_demo"){
                  return(
                    <PManagerDemoForm key={i} pmDemoForm={slice} />
                  )
                }else if(slice.type === "list"){
                  return(
                    <section key={i} className="kb__list kb__body-list">
                      <div className="kb__container--full">
                        <div className="kb--center kb__col-12">{RichText.render(slice.primary.list_title)}</div>
                        <div className="kb__grid-center">
                          <div className="kb__col-8_sm-12">
                            
                            <div className="kb__grid-spaceAround">
                              {slice.fields.map((item,i) => {
                                return(
                                  <div key={i} className="kb__col-5_sm-12">
                                    <div className="kb__list-wrap">
                                      <InlineSVG url={slice.primary.list_image.url} classes="kb__list-image" />
                                      <p className="big-body">{RichText.asText(item.list_text)}</p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )
                } else if (slice.type === "title") {
                  return(
                    <section key={i} data-center-title={slice.primary.center_title}>
                      <div className="kb__container">
                        <div className="kb__grid-noGutter-center">
                          <div className="kb__col-10__sm-12">
                            {RichText.render(slice.primary.single_line_title)}
                          </div>
                        </div>
                      </div>
                    </section>
                  )
                } else {
                  return null
                }

              //Not a slice
              } else {
                return null
              }
            })
          }
        </div>

      }

    </Layout>
  );
}

export default Page;


import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MarketplaceSidebar from './marketplaceSidebar'
import CampaignCard from './campaignCard'
import MarketplacePagination from './marketplacePagination'
import MarketplaceService from '../services/MarketplaceService';
import './marketplaceBody.scss';

function toggleFilters(e){
  const filterBar = document.querySelector('.kb__marketplace-sidebar');

  if(e.target.classList.contains('is-active')){
    e.target.classList.remove('is-active');
    filterBar.classList.remove('is-visible');
  } else {
    e.target.classList.add('is-active');
    filterBar.classList.add('is-visible');
  }
}

const MarketplaceBody = () => {
  const [ campaignType, setCampaignType ] = useState(MarketplaceService.CAMPAIGN_TYPES[1].value);
  const [ page, setPage ] = useState(1);
  const sortOptions = useMemo(() => {
    const t = MarketplaceService.SORT_OPTIONS.filter(o => 
      o.value !== 'staff_pick' || campaignType === 'staff_picks'
    );
    return t;
  }, [campaignType]);
  const [ _sort, setSort ] = useState(MarketplaceService.SORT_OPTIONS[1].value);
  const [ categories, setCategories ] = useState([]);
  const [ campaigns, setCampaigns ] = useState([]);
  const [ search, setSearch ] = useState({
    value: '',
    debounced: '',
  });

  // Ensuring that the selected sort option is a valid option
  const sort = useMemo(() => {
    return sortOptions.find(o => o.value === _sort)?.value ?? sortOptions[0].value;
  }, [_sort, sortOptions]);

  const setSearchProxy = useCallback((value) => {
    setSearch(ps => ({...ps, value}));
  }, [setSearch]);

  /**
   * Handles the change event from the sort select
   * 
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  const changeSort = useCallback(({ target }) => {
    const sortOption = MarketplaceService.SORT_OPTIONS.find(opt => opt.value === target.value);
    sortOption && setSort(sortOption.value);
    sortOption && setPage(1);
  }, [setSort]);

  /**
   * Sets the categories to the categories provided after setting the page to 1
   * 
   * @param {Array} categories
   */
  const setCategoriesProxy = useCallback((categories) => {
    setPage(1);
    setCategories(categories);
  }, [setCategories, setPage]);

  /**
   * Sets the campaign type and resets the selected categories
   * 
   * @param {string} type
   */
  const setCampaignTypeProxy = useCallback((type) => {
    setCategories([]);
    setPage(1);
    setCampaignType(type);
  }, [setCampaignType, setCategories, setPage]);

  /**
   * Gets the campaigns filtered and sorted by the current state
   */
  const getCampaigns = useCallback(async (ctx) => {
    let campaigns;
    const params = {sort, type: campaignType, categories, page};
    if (search.debounced !== '') {
      params.search_term = search.debounced;
    }

    // Getting the campaigns from the provider
    try {
      campaigns = await Promise.race([
        ctx,
        MarketplaceService.getCampaigns(params),
      ]);
    } catch (e) {
      console.error(e);
      return;
    }

    setCampaigns(campaigns);
  }, [sort, campaignType, categories, search.debounced, page]);

  // Fetches camapaigns when state changes
  useEffect(() => {
    let cancel, ctx = new Promise((_, r) => cancel = r);
    getCampaigns(ctx);  
    return () => cancel('Context cancelled');
  }, [getCampaigns]);

  // Setting the debounced search value after a short time
  useEffect(() => {
    if (search.value === search.debounced) return;

    const timeoutId = setTimeout(() => {
      setSearch(ps => ({...ps, debounced: ps.value}));
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [search, setSearch]);

  return (
    <section className="kb__marketplace-body">
      <button className="kb__marketplace-toggle" onClick={toggleFilters}>Filters <div className="kb__arrow--down"></div></button>
      <div className="kb__container--full">
        <div className="kb__grid kb--p-r">
          <MarketplaceSidebar
            setType={setCampaignTypeProxy}
            selectedType={campaignType}
            setCategories={setCategoriesProxy}
            selectedCategories={categories}
            setSearch={setSearchProxy}
            search={search.value}
          />
        
          <div className="kb__col-9_md-12">
            <div className="kb__marketplace-tools">
              
              <div className="kb__field-select">
                <label className="kb__sort-label" htmlFor="kb__sort">Sort by</label> 
                <select id="kb__sort" value={sort} onChange={changeSort}>
                  {sortOptions.map(opt =>
                    <option value={opt.value} key={opt.value}>{opt.displayValue}</option>  
                  )}
                </select>
              </div>

              <div className="kb__field-search" data-desktop={true}>
                <input
                  type="text"
                  aria-label="Search for a campaign"
                  placeholder="Search"
                  onChange={e => setSearchProxy(e.target.value)}
                  value={search.value}
                />
                
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z" fill="#2E2E2E" fillOpacity="0.35"/>
                </svg>
              </div>

            </div>
            
            <div className="kb__grid kb__marketplace-list">
              {campaigns.map((campaign, i) => 
                <CampaignCard key={i} campaign={campaign} />
              )}
            </div>
            <MarketplacePagination
              page={page}
              setPage={setPage}
              totalPages={campaigns.totalPages ?? 1}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MarketplaceBody;
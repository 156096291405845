import React, {Component} from 'react'

import './ecommForm.scss'


class EcommForm extends Component{
  
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://kickbooster.activehosted.com/f/embed.php?id=10';
    script.async = true;

    document.body.appendChild(script);
  }

  render(){


    function createMarkup() {
      return {__html: '._form_10 ._form-content #_form_10_submit {background: var(--color-purple--400) !important; padding: 1.6rem 2rem !important;} ._form_10 ._form-element input{border-color: var(--color-grey--200) !important; }'};
    }

    return(
      <section className="kb__partners-form">
        <div className="kb__container">

          <div className="hiddenFor">
            <div className="_form_10" />
          </div>

          <style dangerouslySetInnerHTML={createMarkup()}></style>
          
        </div>
      </section>
    )
  }
}

export default EcommForm

import React, { useMemo } from 'react';
import iconChevronLeft from '../images/icon-chevron-left.svg';
import iconChevronRight from '../images/icon-chevron-right.svg';

import './marketplacePagination.scss';

const MarketplacePagination = ({
  page,
  setPage,
  totalPages,
}) => {
  const parts = useMemo(() => {
    const maxPages = totalPages;
    const minPages = 1;
    const currentPage = page;
    const adjacentPages = 3;
    const startOverage = Math.max(0, minPages - (currentPage - adjacentPages));
    const endOverage = Math.max(0, (currentPage + adjacentPages) - maxPages);
    const start = Math.max(minPages, currentPage - (endOverage + adjacentPages));
    const end = Math.min(maxPages, currentPage + (startOverage + adjacentPages));

    return new Array(end - start + 1).fill().map((_, i) => i + start);
  }, [page, totalPages]);

  function scrollToTop(){
    var totalOffset = document.querySelector('.kb__marketplace-body').offsetTop - document.querySelector('.kb__header').offsetHeight;
    window.scrollTo(0,totalOffset);
  }

  return (
    <div className="kb__pagination">
      <button
        className="kb__pagination-prev"
        disabled={page === 1}
        onClick={() => {
          setPage(page - 1);
          scrollToTop();
        }}
      >
        <img className="kb__pagination-icon" src={iconChevronLeft} alt="Previous icon" />
      </button>
      {parts.map(part =>
        <button
          key={part}
          className={`kb__pagination-part${part === page ? ' kb__pagination-part--active' : ''}`}
          onClick={() => {
            part !== page && setPage(part);
            scrollToTop();
          }}
        >
          {part}
        </button>  
      )}
      <button
        className="kb__pagination-next"
        disabled={page === totalPages}
        onClick={() => {
          setPage(page + 1);
          scrollToTop();
        }}
      >
        <img className="kb__pagination-icon" src={iconChevronRight} alt="Next icon" />
      </button>
    </div>
  );
}

export default React.memo(MarketplacePagination);
import React, { useCallback, useState, useEffect } from 'react';
import SwiperCore, { Pagination, A11y, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import MarketplaceService from '../services/MarketplaceService';

import IconChevronLeft from '../images/icon-chevron-left-white.svg'
import IconChevronRight from '../images/icon-chevron-right-white.svg'

import './marketplaceHeader.scss'


SwiperCore.use([Pagination, A11y, Navigation, Autoplay]);

const MarketplaceHeader = () => {

  const [ campaigns, setCampaigns ] = useState([]);

  const getCampaigns = useCallback(async () => {
    const campaigns = await MarketplaceService.getCampaigns({ type: 'featured', sort: 'latest'});
    setCampaigns(campaigns);
  }, [setCampaigns]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return(

    <section className="kb__marketplace-header">
      <link rel="prefetch"  href="https://kickbooster.me/marketplace.json?all_categories=true&type=featured&sort=latest"  />
      <div className="kb__container--full-bleed kb--p-r">
        <div className="kb__slider">
          <Swiper 
            autoHeight={true}
            autoplay={{delay: 5000, disableOnInteraction: true}}
            pagination={{el: '.kb__slider-pagination', clickable: true}}
            navigation={{nextEl: '.kb__slider-next', prevEl: '.kb__slider-prev'}}
            grabCursor={true}
            slidesPerView={1}
            loop={false}
          >

              {campaigns.map((featured,i) => {
                let featured_image=featured.image_large_url;
                if(window.innerWidth <= 500)
                  featured_image=featured.image_small_url;
                else if(window.innerWidth <= 800)
                  featured_image=featured.image_medium_url;

                return(
                  <SwiperSlide key={i}>
                    <link rel="prefetch"  href={featured_image}  />
                    <div className="kb__featured-campaign">
                      <div className="kb__featured-campaign-wrapper kb__container--full">
                        <div className="kb__grid-center">
                          <div className="kb__col-10_sm-12">
                            <h6>Featured Campaign</h6>
                            <h3>{featured.name}</h3>
                            <p>{featured.blurb}</p>

                            <div className="kb__actions">
                              <a className="kb__button--secondary kb__button--large" target="_blank" rel="noopener noreferrer" href={featured.kickbooster_redirect_url}>View campaign</a>
                              <a className="kb__button--primary kb__button--large" href={featured.booster_page_url}>Boost campaign</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img src={featured_image} alt={featured.name + ` powered by Kickbooster`} />
                    </div>
                  </SwiperSlide>
                )
              })}
              
          </Swiper>
    
        </div>

        <div className="kb__slider-nav">
          <button className="kb__slider-nav-button kb__slider-prev">
            <img src={IconChevronLeft} alt="" />
          </button>
          
          <div className="kb__slider-pagination"></div>

          <button className="kb__slider-nav-button kb__slider-next">
            <img src={IconChevronRight} alt="" />
          </button>
        </div>

      </div>
    </section>
  )

}

export default MarketplaceHeader



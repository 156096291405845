import React from 'react'
import {RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import SwiperCore, { Pagination, A11y, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Icon5Star from '../images/icon-5-star.svg'
import Shape from './shape'

import IconChevronLeft from '../images/icon-chevron-left.svg'
import IconChevronRight from '../images/icon-chevron-right.svg'

import './featuredReviews.scss'

SwiperCore.use([Pagination, A11y, Navigation, Autoplay]);

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const FeaturedReviews = (props) => {
  
  if(!props.reviews) return null;

  return (
    <section className="kb__featured-reviews">
      <div className="kb__slider">
        <Swiper 
          autoHeight={true}
          autoplay={{delay: 5000, disableOnInteraction: true}}
          pagination={{el: '.kb__slider-pagination', clickable: true}}
          navigation={{nextEl: '.kb__slider-next', prevEl: '.kb__slider-prev'}}
          grabCursor={true}
          slidesPerView={1}
          loop={true}
        >

            {props.reviews.fields.map((review,i)=> {

              return(
                <SwiperSlide key={i}>
                  <div className="kb__review">
                    <div className="kb__container">
                      <div className="kb__grid-center">
                        <div className="kb__col-6__md_8__sm-12 kb__review-card">
                          <img src={Icon5Star} alt="5 star review" />
                          {RichText.render(review.review_quote)}
                          <h4> - {RichText.asText(review.review_customer)}</h4>

                          
                          <div className="kb__shape-wrapper">
                            <Shape shape="Triangle" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}

        </Swiper>
      </div>

    <div className="kb__slider-nav">
      <button className="kb__slider-nav-button kb__slider-prev">
        <img src={IconChevronLeft} alt="" />
      </button>
      
      <div className="kb__slider-pagination"></div>

      <button className="kb__slider-nav-button kb__slider-next">
        <img src={IconChevronRight} alt="" />
      </button>
    </div>
    </section>
  )
}

export default FeaturedReviews


import React from 'react'
import { RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import Shape from './shape'


import './caseStudies.scss'


export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const CaseStudy = (props) => {
  
  if(!props.cases) return null;

  return (
    <section className="kb__case-studies">
      <div className="kb__container">
        <div className="kb__grid-middle">
          {props.cases.primary.ecommerce_cases_title &&
            <div className="kb__col-12 kb--center">
              <h3>{RichText.asText(props.cases.primary.ecommerce_cases_title)}</h3>
            </div>
          }

          {props.cases.fields &&
          
            props.cases.fields.map((study,i) => {
              return(
                <div key={i} className="kb__col-12 kb__case-study">
                  <div className="kb__grid-spaceAround-middle">
                    <div className="kb__col-5_sm-12 kb__case-study-feature">


                    {!study.case_stat_1 
                      ? (
                        <>
                        <span className="title purple kb--italic">{RichText.asText(study.case_testimonial)}</span>
                        {RichText.render(study.case_testimonial_author)}
                        
                        </>
                      )
                      : (
                        <div className="kb__stat">
                          <h2 className="kb_case-study-stat super-headline">{RichText.asText(study.case_stat_1)}</h2>
                          <p>{RichText.asText(study.case_stat_2)}</p>
                        </div>
                      )
                    }

                      
                      <div className="kb__shape-wrapper">
                        <Shape shape="Triangle" />
                      </div>
                    </div>
                    <div className="kb__col-5_sm-12 kb__case-study-info">
                      <img src={study.case_company_image.url} alt={study.case_company_image.alt} />
                      {RichText.render(study.case_company)}
                      {RichText.render(study.case_blurb)}

                      {study.case_cta_link && study.case_cta_text &&
                        <a href={RichLink(study.case_cta_link)}>{RichText.render(study.case_cta_text)}</a>
                      }

                    </div>
                  </div>
                </div>
              )
            }
          )}


          <div className="kb__case-study">

          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudy

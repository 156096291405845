import React, {Component} from 'react'
import {RichText} from 'prismic-reactjs'

import './partnerForm.scss'


function inputKeyPress(e){

  const hiddenForm = document.querySelector('._form_9');

  var element = e.target
  var textEntered = element.value;

  if(hiddenForm){
    if(element.placeholder === "Full Name"){
      hiddenForm.querySelector('input[name=fullname]').value = textEntered;
    }else if(element.placeholder === "Email"){
      hiddenForm.querySelector('input[name=email]').value = textEntered;
    }else if(element.placeholder === "Company"){
      hiddenForm.querySelector('input[name=customer_account]').value = textEntered;
    }else if(element.placeholder === "Position"){
      hiddenForm.querySelector('input[data-name=position_at_company]').value = textEntered;
    }else if(element.placeholder === "Phone"){
      hiddenForm.querySelector('input[name=phone]').value = textEntered;
    }
  } 
}


function formSubmit(e){

  const hiddenForm = document.querySelector('._form_9');

  var nameEntered = document.querySelector('#kb__partner-full-name');
  var emailEntered = document.querySelector('#kb__partner-email');
  var companyEntered = document.querySelector('#kb__partner-company');
  var positionEntered = document.querySelector('#kb__partner-position');
  
  if(hiddenForm){
    if(nameEntered.value !== "" && emailEntered.value !== "" && companyEntered.value !== "" && positionEntered.value !== ""){
      e.preventDefault();
      hiddenForm.querySelector('#_form_9_submit').click();

      var formSuccess = document.querySelector('.form-success');
      var signupForm = document.querySelector('.kb__partner-form');

      setTimeout(function(){ 
        signupForm.reset();
      }, 500);

      formSuccess.classList.add('form-success--active');
      
      setTimeout(function(){ 
        formSuccess.classList.remove('form-success--active');
      }, 5000);

    }
  }
}

class PartnerForm extends Component{
  
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://kickbooster.activehosted.com/f/embed.php?id=9';
    script.async = true;

    document.body.appendChild(script);
  }

  render(){
    return(
      <section className="kb__partners-form">
        <div className="kb__container">

          <div className="hiddenForm">
            <div className="_form_9" />
          </div>
          
          {this.props.block.primary.partners_form_title &&
            <div className="purple"><h4>{RichText.asText(this.props.block.primary.partners_form_title)}</h4></div>
          }

          <form className="kb__partner-form">

            <h4 className="purple">Say hello! <span role="img" aria-label="emoji hand waving">🙂</span></h4>

            <p className="big-body">Let us know how to reach you. One of our team members will be in touch soon.</p>

            <div className="form-success">
              <span>Thanks for reaching out, talk soon! <span role="img" aria-label="emoji hand waving">👋</span></span>
            </div>
          
            <input id="kb__partner-full-name" type="text" required placeholder="Full Name" aria-label="Partner contact: please enter your full name" onChange={inputKeyPress} />
            
            <input id="kb__partner-email" type="email" placeholder="Email" required aria-label="Partner contact: please enter your work email" onChange={inputKeyPress} />

            <input id="kb__partner-company" type="text" placeholder="Company" required aria-label="Partner contact: please enter the company you work for" onChange={inputKeyPress} />

            <input id="kb__partner-position" type="text" placeholder="Position" required aria-label="Partner contact: please enter the name of your position there" onChange={inputKeyPress} />

            <input id="kb__partner-phone" type="tel" placeholder="Phone" aria-label="Partner contact: please enter your work phone number" onChange={inputKeyPress} />
            
            <button className="kb__button--primary kb__button--large" type="submit" onClick={formSubmit}>Submit</button>


          </form>
        </div>
      </section>
    )
  }
}

export default PartnerForm

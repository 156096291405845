import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import SuccessCard from './successCard'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const SuccessList = (props) => {

  const data = useStaticQuery(graphql`
    query successListQuery {
      prismic {
        allSuccess_storys(sortBy: meta_firstPublicationDate_DESC) {
          edges {
            node {
              success_story_sub_title
              success_story_title
              success_story_image
              _linkType
              _meta {
                uid
                type
                tags
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.prismic.allSuccess_storys.edges;

  if (!posts) return null;

  return (
    <section className="kb__blog-list">
      <div className="kb__container--full">
        <div className="kb__grid-center">
          <div className="kb__col-10_md-12_sm-12">
            <div className="kb__grid-center">
              
              {posts && 
                posts.map((post,i) =>{
                  return(
                    <SuccessCard key={i} success={post} />
                  )
                })
              }
              
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessList
import React, { useMemo, useCallback } from 'react';
import MarketplaceService from '../services/MarketplaceService';

import './marketplaceSidebar.scss'

function createMarkup(svg) {
  return {__html: svg};
}

const MarketplaceSidebar = ({
  setType,
  selectedType,
  setCategories,
  selectedCategories,
  setSearch,
  search,
}) => {
  const selectedTypeObj = useMemo(() => {
    return MarketplaceService.CAMPAIGN_TYPES.find(t => t.value === selectedType);
  }, [selectedType]);

  /**
   * Toggles a the provided catageory
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const setCategoriesProxy = useCallback((e) => {
    const category = e.target.value;

    if (e.target.checked) {
      setCategories(ps => [...ps, category]);
    } else {
      setCategories(ps => ps.filter(c => c !== category));
    }
  }, [setCategories]);

  /**
   * Clears all selected categories
   */
  const unselectAllCategories = useCallback(() => {
    setCategories([]);
  }, [setCategories]);

  return (
      <aside className="kb__col-3_md-4_sm-12 kb__marketplace-sidebar">
        <div className="kb__marketplace-sidebar-wrapper">
          <div className="kb__field-search" data-mobile={true}>
            <input
              type="text"
              aria-label="Search for a campaign"
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z" fill="#2E2E2E" fillOpacity="0.35"/>
            </svg>
          </div>
          <div className="kb__marketplace-types">
            {MarketplaceService.CAMPAIGN_TYPES.map(type => 
              <button
                onClick={() => setType(type.value)}
                className={`big-body kb__marketplace-type ${type.value === selectedType ? 'kb__marketplace-type--active' : ''}`}
                type="button"
                key={type.value}>
                  <span dangerouslySetInnerHTML={createMarkup(type.icon)} />
                  {type.displayValue}
              </button>
            )}
          </div>
          {selectedTypeObj.categories.length > 0 && (
            <div className="kb__marketplace-categories">
              <h6>Category</h6>
              <div className="kb__form-field">
                <label htmlFor="category-all">
                  <div className="kb__checkbox-container">
                    <input
                      onChange={unselectAllCategories}
                      type="checkbox"
                      id="category-all"
                      aria-label="Category Filter:All"
                      checked={selectedCategories.length === 0}
                    />
                    <div className="kb__checkbox"></div>
                  </div>
                  All
                </label>
              </div>
              {selectedTypeObj.categories.map(category => 
                <div key={category.value} className="kb__form-field">
                  <label htmlFor={`category-${category.value}`}>
                    <div className="kb__checkbox-container">
                      <input
                        onChange={setCategoriesProxy}
                        value={category.value}
                        type="checkbox"
                        id={`category-${category.value}`}
                        aria-label={`Category Filter:${category.displayValue}`}
                        checked={selectedCategories.some(c => c === category.value)}
                      />
                      <div className="kb__checkbox"></div>
                    </div>
                    {category.displayValue}
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </aside>
  )

}

export default MarketplaceSidebar

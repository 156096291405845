import React from "react"
import {Link} from "prismic-reactjs"
import linkResolver from '../utils/linkResolver'
import BlogListAll from './blogListAll'
import BlogListCreators from './BlogListCreators'
import BlogListNews from './BlogListNews'
import BlogListTips from './BlogListTips'
import BlogListEcommerce from './BlogListEcommerce'
import BlogListCaseStudies from './BlogListCaseStudies'
import BlogListFeaturedCampaigns from './BlogListFeaturedCampaigns'

import './blogCard.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const BlogList = (props) => {

  if (!props) return null;

  var BlogCategory = ""

  if(props.category === "Creator's Corner"){
    BlogCategory = BlogListCreators;
  } else if(props.category === "Kickbooster News") {
    BlogCategory = BlogListNews;
  } else if(props.category === "eCommerce") {
    BlogCategory = BlogListEcommerce;
  } else if(props.category === "Crowdfunding Tips") {
    BlogCategory = BlogListTips;
  } else if(props.category === "Case Studies") {
    BlogCategory = BlogListCaseStudies;
  } else if(props.category === "Featured Campaigns") {
    BlogCategory = BlogListFeaturedCampaigns;
  } else {
    BlogCategory = BlogListAll;
  }

  return (


      <BlogCategory />
    
  )
}

export default BlogList
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {RichText} from 'prismic-reactjs'
import SwiperCore, { Pagination, A11y, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import IconChevronLeft from '../images/icon-chevron-left.svg'
import IconChevronRight from '../images/icon-chevron-right.svg'

import './featuredSuccessStories.scss'

SwiperCore.use([Pagination, A11y, Navigation, Autoplay]);

const FeaturedSuccessStories = () => {
  const data = useStaticQuery(graphql`
  {
    prismic {
      allSuccess_storys(where: {success_story_featured: true}) {
        edges {
          node {
            success_story_title
            success_story_testimonial
            success_story_image
            success_story_rich_text
            success_story_sub_title
            success_story_website {
              _linkType
            }
            success_story_featured
            body {
              ... on PRISMIC_Success_storyBodyStats {
                type
                label
              }
            }
            success_story_campaign {
              _linkType
            }
            _linkType
            _meta {
              uid
              type
              tags
            }
          }
        }
      }
    }
  }  
  `)

  

  const featuredStories = data.prismic.allSuccess_storys.edges;

  if(!featuredStories[0].node) return null;

  return (
    <section className="kb__featured-stories">
      <div className="kb__slider">
        <Swiper 
          autoHeight={true}
          autoplay={{delay: 8000, disableOnInteraction: true}}
          pagination={{el: '.kb__slider-pagination', clickable: true}}
          navigation={{nextEl: '.kb__slider-next', prevEl: '.kb__slider-prev'}}
          grabCursor={true}
          slidesPerView={1}
          loop={true}
        >

            {featuredStories.map((featured,i) => {
              return(
                <SwiperSlide key={i}>
                  <div className="kb__featured-story">
                    <div className="kb__container--narrow">
                      <div className="kb__grid-center-middle">
                        <div className="kb__col-5_sm-12">
                          <img className="kb__featured-story-image" src={featured.node.success_story_image.square.url} alt={featured.name + ` powered by Kickbooster`} />
                        </div> 
                        <div className="kb__col-6_sm-12" data-push-left="off-1">
                          <h4 className="purple">{RichText.asText(featured.node.success_story_sub_title)}</h4>
                          <p>{RichText.asText(featured.node.success_story_testimonial)}</p>

                          <a className="kb__button--arrow big-body" href={'/success/'+featured.node._meta.uid} title="Read more about their success">
                            Read more 
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.64099 1.35156L7.4149 0.571094C7.74259 0.240625 8.27247 0.240625 8.59668 0.571094L15.3736 7.40195C15.7013 7.73242 15.7013 8.2668 15.3736 8.59375L8.59668 15.4281C8.26899 15.7586 7.7391 15.7586 7.4149 15.4281L6.64099 14.6477C6.30981 14.3137 6.31678 13.7688 6.65493 13.4418L10.8557 9.40586H0.83666C0.373011 9.40586 0 9.02969 0 8.56211V7.43711C0 6.96953 0.373011 6.59336 0.83666 6.59336H10.8557L6.65493 2.55742C6.31329 2.23047 6.30632 1.68555 6.64099 1.35156Z" fill="#3A3A3A"/>
                            </svg>
                          </a>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
            
        </Swiper>
  
      </div>

      <div className="kb__slider-nav">
        <button className="kb__slider-nav-button kb__slider-prev">
          <img src={IconChevronLeft} alt="" />
        </button>
        
        <div className="kb__slider-pagination"></div>

        <button className="kb__slider-nav-button kb__slider-next">
          <img src={IconChevronRight} alt="" />
        </button>
      </div>
    </section>
  )
}

export default FeaturedSuccessStories